html, body, #root {
    height: 100%;
    width: 100%;
}

.App {
    background-color: #1a395d;
    height: 100%;
}

.place-label {
    font-weight: 200;
    fill: black;
    text-transform: uppercase;
    text-anchor: middle;
    color: #000;
    font-family: arial, helvetica, sans-serif;
    font-size: 2px;
    line-height: 28px;
    letter-spacing: 0.133em;
    pointer-events: none;
}
